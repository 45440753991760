import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { default as decode } from 'jwt-decode';
import { AuthGuardService } from '../services/auth-guard.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService, private alert: AlertService, private router: Router, private auth: AuthGuardService) {
    this.auth.verifyAccess();
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  loginForm: FormGroup;

  loader: boolean;
  dataUser: any;

  ngOnInit() {
  }

  login() {
    if (!this.loginForm.invalid) {
      this.loader = true;
      this.authService.login(this.loginForm.value).then(res => {
        if (res.success) {
          this.alert.alertMax('Correcto', res.message, 'success');
          localStorage.setItem('token', res.data);
          this.dataUser = decode(res.data);
          this.router.navigate([this.dataUser.role]);
        } else {
          this.alert.alertMax('Error', res.message, 'error');
        }
        this.loader = false;
      });
    } else {
      this.alert.alertMax('Error', 'Completa los campos', 'warning');
    }
  }

  viewPassword() {
    var tipo: any = document.getElementById("password");
    var text: any = document.getElementById("text");
    if (tipo.type == "password") {
      tipo.type = "text";
      text.textContent = "Ocultar Contraseña";
    } else {
      tipo.type = "password";
      text.textContent = "Ver Contraseña";
    }
  }

}
