import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  constructor(private authService: AuthService, private alert: AlertService, private router: Router) {
    this.loader = false;
    this.countries = [
      {
        id: 1,
        nombre: 'Guatemala'
      },
      {
        id: 2,
        nombre: 'Afghanistan'
      },
      {
        id: 3,
        nombre: 'Albania'
      },
      {
        id: 4,
        nombre: 'Algeria'
      },
      {
        id: 5,
        nombre: 'American Samoa'
      },
      {
        id: 6,
        nombre: 'Andorra'
      },
      {
        id: 7,
        nombre: 'Angola'
      },
      {
        id: 8,
        nombre: 'Anguilla'
      },
      {
        id: 9,
        nombre: 'Antigua and Barbuda'
      },
      {
        id: 10,
        nombre: 'Argentina'
      },
      {
        id: 11,
        nombre: 'Armenia'
      },
      {
        id: 12,
        nombre: 'Aruba'
      },
      {
        id: 13,
        nombre: 'Australia'
      },
      {
        id: 14,
        nombre: 'Austria'
      },
      {
        id: 15,
        nombre: 'Azerbaijan Republic'
      },
      {
        id: 16,
        nombre: 'Bahamas'
      },
      {
        id: 17,
        nombre: 'Bahrain'
      },
      {
        id: 18,
        nombre: 'Bangladesh'
      },
      {
        id: 19,
        nombre: 'Barbados'
      },
      {
        id: 20,
        nombre: 'Belarus'
      },
      {
        id: 21,
        nombre: 'Belgium'
      },
      {
        id: 22,
        nombre: 'Belize'
      },
      {
        id: 23,
        nombre: 'Benin'
      },
      {
        id: 24,
        nombre: 'Bermuda'
      },
      {
        id: 25,
        nombre: 'Bhutan'
      },
      {
        id: 26,
        nombre: 'Bolivia'
      },
      {
        id: 27,
        nombre: 'Bosnia and Herzegovina'
      },
      {
        id: 28,
        nombre: 'Botswana'
      },
      {
        id: 29,
        nombre: 'Brazil'
      },
      {
        id: 30,
        nombre: 'British Virgin Islands'
      },
      {
        id: 31,
        nombre: 'Brunei Darussalam'
      },
      {
        id: 32,
        nombre: 'Bulgaria'
      },
      {
        id: 33,
        nombre: 'Burkina Faso'
      },
      {
        id: 34,
        nombre: 'Burma'
      },
      {
        id: 35,
        nombre: 'Burundi'
      },
      {
        id: 36,
        nombre: 'Cambodia'
      },
      {
        id: 37,
        nombre: 'Cameroon'
      },
      {
        id: 38,
        nombre: 'Canada'
      },
      {
        id: 39,
        nombre: 'Cape Verde Islands'
      },
      {
        id: 40,
        nombre: 'Cayman Islands'
      },
      {
        id: 41,
        nombre: 'Central African Republic'
      },
      {
        id: 42,
        nombre: 'Chad'
      },
      {
        id: 43,
        nombre: 'Chile'
      },
      {
        id: 44,
        nombre: 'China'
      },
      {
        id: 45,
        nombre: 'Colombia'
      },
      {
        id: 46,
        nombre: 'Comoros'
      },
      {
        id: 47,
        nombre: 'Congo, Democratic Republic of'
      },
      {
        id: 48,
        nombre: 'Cook Islands'
      },
      {
        id: 49,
        nombre: 'Costa Rica'
      },
      {
        id: 50,
        nombre: 'Cote d Ivoire (Ivory Coast)'
      },
      {
        id: 51,
        nombre: 'Croatia, Democratic Republic of the'
      },
      {
        id: 52,
        nombre: 'Cuba'
      },
      {
        id: 53,
        nombre: 'Cyprus'
      },
      {
        id: 54,
        nombre: 'Czech Republic'
      },
      {
        id: 55,
        nombre: 'Denmark'
      },
      {
        id: 56,
        nombre: 'Djibouti'
      },
      {
        id: 57,
        nombre: 'Dominica'
      },
      {
        id: 58,
        nombre: 'Ecuador'
      },
      {
        id: 59,
        nombre: 'Egypt'
      },
      {
        id: 60,
        nombre: 'El Salvador'
      },
      {
        id: 61,
        nombre: 'Equatorial Guinea'
      },
      {
        id: 62,
        nombre: 'Eritrea'
      },
      {
        id: 63,
        nombre: 'Españaa'
      },
      {
        id: 64,
        nombre: 'Estados Unidos'
      },
      {
        id: 65,
        nombre: 'Estonia'
      },
      {
        id: 66,
        nombre: 'Ethiopia'
      },
      {
        id: 67,
        nombre: 'Falkland Islands (Islas Makvinas)'
      },
      {
        id: 68,
        nombre: 'Fiji'
      },
      {
        id: 69,
        nombre: 'Finland'
      },
      {
        id: 70,
        nombre: 'France'
      },
      {
        id: 71,
        nombre: 'French Guiana'
      },
      {
        id: 72,
        nombre: 'French Polynesia'
      },
      {
        id: 73,
        nombre: 'Gabon Republic'
      },
      {
        id: 74,
        nombre: 'Gambia'
      },
      {
        id: 75,
        nombre: 'Georgia'
      },
      {
        id: 76,
        nombre: 'Germany'
      },
      {
        id: 77,
        nombre: 'Ghana'
      },
      {
        id: 78,
        nombre: 'Gibraltar'
      },
      {
        id: 79,
        nombre: 'Greece'
      },
      {
        id: 80,
        nombre: 'Greenland'
      },
      {
        id: 81,
        nombre: 'Grenada'
      },
      {
        id: 82,
        nombre: 'Guadeloupe'
      },
      {
        id: 83,
        nombre: 'Guam'
      },
      {
        id: 84,
        nombre: 'Guernsey'
      },
      {
        id: 85,
        nombre: 'Guinea'
      },
      {
        id: 86,
        nombre: 'Guinea-Bissau'
      },
      {
        id: 87,
        nombre: 'Guyana'
      },
      {
        id: 88,
        nombre: 'Haiti'
      },
      {
        id: 89,
        nombre: 'Honduras'
      },
      {
        id: 90,
        nombre: 'Hong Kong'
      },
      {
        id: 91,
        nombre: 'Hungary'
      },
      {
        id: 92,
        nombre: 'Iceland'
      },
      {
        id: 93,
        nombre: 'Inglaterra'
      },
      {
        id: 94,
        nombre: 'Japan'
      },
      {
        id: 95,
        nombre: 'Jersey'
      },
      {
        id: 96,
        nombre: 'Jordan'
      },
      {
        id: 97,
        nombre: 'Kazakhstan'
      },
      {
        id: 98,
        nombre: 'Kenya Coast Republic'
      },
      {
        id: 99,
        nombre: 'Kiribati'
      },
      {
        id: 100,
        nombre: 'Korea, North'
      },
      {
        id: 101,
        nombre: 'Korea, South'
      },
      {
        id: 102,
        nombre: 'Kuwait'
      },
      {
        id: 103,
        nombre: 'Kyrgyzstan'
      },
      {
        id: 104,
        nombre: 'Laos'
      },
      {
        id: 105,
        nombre: 'Latvia'
      },
      {
        id: 106,
        nombre: 'Lebanon, South'
      },
      {
        id: 107,
        nombre: 'Lesotho'
      },
      {
        id: 108,
        nombre: 'Liberia'
      },
      {
        id: 109,
        nombre: 'Libya'
      },
      {
        id: 110,
        nombre: 'Liechtenstein'
      },
      {
        id: 111,
        nombre: 'Lithuania'
      },
      {
        id: 112,
        nombre: 'Luxembourg'
      },
      {
        id: 113,
        nombre: 'Macau'
      },
      {
        id: 114,
        nombre: 'Macedonia'
      },
      {
        id: 115,
        nombre: 'Madagascar'
      },
      {
        id: 116,
        nombre: 'Malawi'
      },
      {
        id: 117,
        nombre: 'Malaysia'
      },
      {
        id: 118,
        nombre: 'Maldives'
      },
      {
        id: 119,
        nombre: 'Mali'
      },
      {
        id: 120,
        nombre: 'Malta'
      },
      {
        id: 121,
        nombre: 'Marshall Islands'
      },
      {
        id: 122,
        nombre: 'Martinique'
      },
      {
        id: 123,
        nombre: 'Mauritania'
      },
      {
        id: 124,
        nombre: 'Mauritius'
      },
      {
        id: 125,
        nombre: 'Mayotte'
      },
      {
        id: 126,
        nombre: 'Mexico'
      },
      {
        id: 127,
        nombre: 'Moldova'
      },
      {
        id: 128,
        nombre: 'Monaco'
      },
      {
        id: 129,
        nombre: 'Mongolia'
      },
      {
        id: 130,
        nombre: 'Montserrat'
      },
      {
        id: 131,
        nombre: 'Morocco'
      },
      {
        id: 132,
        nombre: 'Mozambique'
      },
      {
        id: 133,
        nombre: 'Namibia'
      },
      {
        id: 134,
        nombre: 'Nauru'
      },
      {
        id: 135,
        nombre: 'Nepal'
      },
      {
        id: 136,
        nombre: 'Netherlands'
      },
      {
        id: 137,
        nombre: 'Netherlands Antilles'
      },
      {
        id: 138,
        nombre: 'New Caledonia'
      },
      {
        id: 139,
        nombre: 'New Zealand'
      },
      {
        id: 140,
        nombre: 'Nicaragua'
      },
      {
        id: 141,
        nombre: 'Niger'
      },
      {
        id: 142,
        nombre: 'Nigeria'
      },
      {
        id: 143,
        nombre: 'Niue'
      },
      {
        id: 144,
        nombre: 'Norway'
      },
      {
        id: 145,
        nombre: 'Oman'
      },
      {
        id: 146,
        nombre: 'Pakistan'
      },
      {
        id: 147,
        nombre: 'Palau'
      },
      {
        id: 148,
        nombre: 'Panama'
      },
      {
        id: 149,
        nombre: 'Papua New Guinea'
      },
      {
        id: 150,
        nombre: 'Paraguay'
      },
      {
        id: 151,
        nombre: 'Peru'
      },
      {
        id: 152,
        nombre: 'Philippines'
      },
      {
        id: 153,
        nombre: 'Poland'
      },
      {
        id: 154,
        nombre: 'Portugal'
      },
      {
        id: 155,
        nombre: 'Puerto Rico'
      },
      {
        id: 156,
        nombre: 'Qatar'
      },
      {
        id: 157,
        nombre: 'República Dominicana'
      },
      {
        id: 158,
        nombre: 'Romania'
      },
      {
        id: 159,
        nombre: 'Russian Federation'
      },
      {
        id: 160,
        nombre: 'Rwanda'
      },
      {
        id: 161,
        nombre: 'Saint Helena'
      },
      {
        id: 162,
        nombre: 'Saint Kitts-Nevis'
      },
      {
        id: 163,
        nombre: 'Saint Lucia'
      },
      {
        id: 164,
        nombre: 'Saint Pierre and Miquelon'
      },
      {
        id: 165,
        nombre: 'Saint Vincent and the Grenadines'
      },
      {
        id: 166,
        nombre: 'San Marino'
      },
      {
        id: 167,
        nombre: 'Saudi Arabia'
      },
      {
        id: 168,
        nombre: 'Senegal'
      },
      {
        id: 169,
        nombre: 'Seychelles'
      },
      {
        id: 170,
        nombre: 'Sierra Leone'
      },
      {
        id: 171,
        nombre: 'Singapore'
      },
      {
        id: 172,
        nombre: 'Slovakia'
      },
      {
        id: 173,
        nombre: 'Slovenia'
      },
      {
        id: 174,
        nombre: 'Solomon Islands'
      },
      {
        id: 175,
        nombre: 'Somalia'
      },
      {
        id: 176,
        nombre: 'South Africa'
      },
      {
        id: 177,
        nombre: 'Sri Lanka'
      },
      {
        id: 178,
        nombre: 'Sudan'
      },
      {
        id: 179,
        nombre: 'Suriname'
      },
      {
        id: 180,
        nombre: 'Svalbard'
      },
      {
        id: 181,
        nombre: 'Swaziland'
      },
      {
        id: 182,
        nombre: 'Sweden'
      },
      {
        id: 183,
        nombre: 'Switzerland'
      },
      {
        id: 184,
        nombre: 'Syria'
      },
      {
        id: 185,
        nombre: 'Tahiti'
      },
      {
        id: 186,
        nombre: 'Taiwan'
      },
      {
        id: 187,
        nombre: 'Tajikistan'
      },
      {
        id: 188,
        nombre: 'Tanzania'
      },
      {
        id: 189,
        nombre: 'Thailand'
      },
      {
        id: 190,
        nombre: 'Togo'
      },
      {
        id: 191,
        nombre: 'Tonga'
      },
      {
        id: 192,
        nombre: 'Trinidad and Tobago'
      },
      {
        id: 193,
        nombre: 'Tunisia'
      },
      {
        id: 194,
        nombre: 'Turkey'
      },
      {
        id: 195,
        nombre: 'Turkmenistan'
      },
      {
        id: 196,
        nombre: 'Turks and Caicos Islands'
      },
      {
        id: 197,
        nombre: 'Tuvalu'
      },
      {
        id: 198,
        nombre: 'Uganda'
      },
      {
        id: 199,
        nombre: 'Ukraine'
      },
      {
        id: 200,
        nombre: 'United Arab Emirates'
      },
      {
        id: 201,
        nombre: 'Uruguay'
      },
      {
        id: 202,
        nombre: 'Uzbekistan'
      },
      {
        id: 203,
        nombre: 'Vanuatu'
      },
      {
        id: 204,
        nombre: 'Vatican City, State'
      },
      {
        id: 205,
        nombre: 'Venezuela'
      },
      {
        id: 206,
        nombre: 'Vietnam'
      },
      {
        id: 207,
        nombre: 'Virgin Islands (U.S.)'
      },
      {
        id: 208,
        nombre: 'Wallis and Futuna'
      },
      {
        id: 209,
        nombre: 'Western Sahara'
      },
      {
        id: 210,
        nombre: 'Western Samoa'
      },
      {
        id: 211,
        nombre: 'Yemen'
      },
      {
        id: 212,
        nombre: 'Yugoslavia'
      },
      {
        id: 213,
        nombre: 'Zambia'
      },
      {
        id: 214,
        nombre: 'Zimbabwe'
      }
    ];
    this.cities = [
      {
        codigo_departamento: 1,
        descripcion_departamento: 'Guatemala'
      },
      {
        codigo_departamento: 2,
        descripcion_departamento: 'El Progreso'
      },
      {
        codigo_departamento: 3,
        descripcion_departamento: 'Sacatepéquez'
      },
      {
        codigo_departamento: 4,
        descripcion_departamento: 'Chimaltenango'
      },
      {
        codigo_departamento: 5,
        descripcion_departamento: 'Escuintla'
      },
      {
        codigo_departamento: 6,
        descripcion_departamento: 'Santa Rosa'
      },
      {
        codigo_departamento: 7,
        descripcion_departamento: 'Sololá'
      },
      {
        codigo_departamento: 8,
        descripcion_departamento: 'Totonicapán'
      },
      {
        codigo_departamento: 9,
        descripcion_departamento: 'Quetzaltenango'
      },
      {
        codigo_departamento: 10,
        descripcion_departamento: 'Suchitepéquez'
      },
      {
        codigo_departamento: 11,
        descripcion_departamento: 'Retalhuleu'
      },
      {
        codigo_departamento: 12,
        descripcion_departamento: 'San Marcos'
      },
      {
        codigo_departamento: 13,
        descripcion_departamento: 'Huehuetenango'
      },
      {
        codigo_departamento: 14,
        descripcion_departamento: 'Quiché'
      },
      {
        codigo_departamento: 15,
        descripcion_departamento: 'Baja Verapaz'
      },
      {
        codigo_departamento: 16,
        descripcion_departamento: 'Alta Verapaz'
      },
      {
        codigo_departamento: 17,
        descripcion_departamento: 'Petén'
      },
      {
        codigo_departamento: 18,
        descripcion_departamento: 'Izabal'
      },
      {
        codigo_departamento: 19,
        descripcion_departamento: 'Zacapa'
      },
      {
        codigo_departamento: 20,
        descripcion_departamento: 'Chiquimula'
      },
      {
        codigo_departamento: 21,
        descripcion_departamento: 'Jalapa'
      },
      {
        codigo_departamento: 22,
        descripcion_departamento: 'Jutiapa'
      }
    ];
    this.userForm = new FormGroup({
      first_name: new FormControl('', [Validators.required]),
      last_name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      birthday: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      genre: new FormControl('', [Validators.required]),
      role: new FormControl('user', [Validators.required]),
    });
  }

  userForm: FormGroup;

  formulario_inscripcion: any;
  fotografia_celuda: any;
  curriculum_viate: any;
  countries = [];
  cities = [];
  loader: boolean;
  dataUser: any;

  ngOnInit() {
  }

  registerUser() {
    if (!this.userForm.invalid) {
      this.loader = true;
      this.authService.register(this.userForm.value).then(res => {
        this.loader = false;
        if (res.success) {
          swal.fire(
            res.message,
            'Hemos enviado un correo con tus credenciales',
            'success'
          );
          this.router.navigate(['login']);
        } else {
          this.alert.alertMax('Error', res.message, 'error');
        }
      });
    } else {
      this.alert.alertMax('Error', 'Completa los campos', 'warning');
    }
  }

}
