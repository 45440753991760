import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { default as decode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class RootService {

  constructor(private httpClient: HttpClient) {
  }

  auth: string;
  dataUser: any;

  url = environment.url;

  get(path: string) {
    return this.httpClient.get(this.url + this.getAuth() + path, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }
    }).toPromise();
  }

  post(path: string, body: any) {
    return this.httpClient.post(this.url + this.getAuth() + path, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }
    }).toPromise();
  }

  put(path: string, body: any) {
    return this.httpClient.put(this.url + this.getAuth() + path, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }
    }).toPromise();
  }

  delete(path: string) {
    return this.httpClient.delete(this.url + this.getAuth() + path, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
      }
    }).toPromise();
  }

  authPost(path: string, body: any) {
    return this.httpClient.post(this.url + path, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).toPromise();
  }

  authPut(path: string, body: any) {
    return this.httpClient.put(this.url + path, body, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).toPromise();
  }

  file(path: string, body: any) {
    return this.httpClient.post(this.url + path, body).toPromise();
  }

  getAuth() {
    this.dataUser = decode(localStorage.getItem('token'));
    this.auth = '/' + this.dataUser.role;
    return this.auth;
  }

}
