import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router) { }

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }

  isAuthenticated(): boolean {
    try {
      const tokenString = localStorage.getItem('token');
      if (!tokenString) {
        return false;
      }
      const token: any = decode(tokenString);
      return token.id ? true : false;
    } catch (error) {
      return false;
    }
  }

  verifyAccess() {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      try {
        const token: any = decode(tokenString);
        if (token.role) {
          if (this.router.url.split('/')[1] !== token.role) {
            this.router.navigate([token.role]);
          }
        } else {
          this.router.navigate(['login']);
        }
      } catch (error) {
        this.router.navigate(['login']);
      }
    } else {
      this.router.navigate(['login']);
    }
  }
}
