import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { default as decode } from 'jwt-decode';

@Component({
  selector: 'app-lost-password',
  templateUrl: './lost-password.component.html',
  styleUrls: ['./lost-password.component.css']
})
export class LostPasswordComponent implements OnInit {

  constructor(private authService: AuthService, private alert: AlertService, private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.lostPasswordForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.email])
    });
    this.activatedRoute.params.subscribe(res => {
      if (res.email) {
        this.lostPasswordForm.controls.email.setValue(res.email);
      }
    });
  }

  lostPasswordForm: FormGroup;

  loader: boolean;
  dataUser: any;

  ngOnInit() {
  }

  lostPassword() {
    if (!this.lostPasswordForm.invalid) {
      this.loader = true;
      this.authService.lostPassword(this.lostPasswordForm.value).then(res => {
        this.loader = false;
        if (res.success) {
          this.alert.alertMax('Correcto', res.message, 'success');
          this.lostPasswordForm.reset();
        } else {
          this.alert.alertMax('Error', res.message, 'error');
        }
      });
    } else {
      this.alert.alertMax('Error', 'Completa los campos', 'warning');
    }
  }

}
