// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  //enlace produccion
   url:'https://app-api.agog.com.gt/api-agog',
   //pruebas entorno de desarrollo
   //url:'http://localhost:8080',
  firebase: {
    apiKey: 'AIzaSyCSFySbAZWMToW0fF0avSbniE6YpPeLBho',
    authDomain: 'chat-agog-b9b6d.firebaseapp.com',
    databaseURL: 'https://chat-agog-b9b6d.firebaseio.com',
    projectId: 'chat-agog-b9b6d',
    storageBucket: 'chat-agog-b9b6d.appspot.com',
    messagingSenderId: '649896642399',
    appId: '1:649896642399:web:da1134b2cf6195be8458ea'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
