import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() { }

  alertMin(title, icon) {
    const toast = swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
    });
    toast.fire({
      title,
      icon,
      timer: 5000,
      timerProgressBar: true
    });
  }

  alertMax(title, text, icon) {
    swal.fire({
      title,
      text,
      icon,
      timer: 5000,
      timerProgressBar: true
    });
  }


}
