<div uk-height-viewport class="uk-flex uk-flex-middle">
  <div class="uk-width-2-3@m uk-width-1-2@s m-auto rounded">
    <div class="uk-child-width-1-1@m uk-grid-collapse bg-gradient-grey" uk-grid>

      <div class="uk-margin-auto-vertical uk-text-center uk-animation-scale-up p-3 uk-light">
        <!-- <i class=" uil-graduation-hat icon-large"></i> -->
        <img width="150" src="assets/images/logo-light.png" alt="">
        <br>
        <h1>AGOG Virtual</h1>
        <p>Asociación de Ginecología y Obstetricia de Guatemala</p>
      </div>

      <div class="uk-card-default p-6">
        <div class="uk-text-center">
          <h2 class="mb-0">Regístrate</h2>
          <p class="my-2">Crea tu cuenta</p>
        </div>

        <hr>

        <form [formGroup]="userForm" class="uk-child-width-1-1 uk-grid-small" uk-grid>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label class="uk-form-label"> Nombre</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-user"></i>
                </span>
                <input formControlName="first_name" class="uk-input" type="text" placeholder="Nombre">
              </div>
            </div>
          </div>
          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label class="uk-form-label"> Apellido</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-user"></i>
                </span>
                <input formControlName="last_name" class="uk-input" type="text" placeholder="Apellido">
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label class="uk-form-label">Correo Electrónico</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-mail"></i>
                </span>
                <input formControlName="email" class="uk-input" type="email" placeholder="Correo">
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label class="uk-form-label">Celular</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-line-awesome-phone-square"></i>
                </span>
                <input formControlName="phone" class="uk-input" type="tel" placeholder="Celular">
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label class="uk-form-label">País</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-map-pin"></i>
                </span>
                <select formControlName="country" class="uk-input uk-select">
                  <option value="" hidden>País</option>
                  <option *ngFor="let i of countries" [value]="i.nombre">
                    {{i.nombre}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label *ngIf="this.userForm.controls.country.value === 'Guatemala'"
                class="uk-form-label">Departamento</label>
              <label *ngIf="this.userForm.controls.country.value !== 'Guatemala'" class="uk-form-label">Ciudad</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-map-pin"></i>
                </span>
                <select *ngIf="this.userForm.controls.country.value === 'Guatemala'" formControlName="city"
                  class="uk-input uk-select">
                  <option value="" hidden>Departamento</option>
                  <option *ngFor="let i of cities" [value]="i.descripcion_departamento">
                    {{i.descripcion_departamento}}
                  </option>
                </select>
                <input *ngIf="this.userForm.controls.country.value !== 'Guatemala'" formControlName="city"
                  class="uk-input" type="text" placeholder="Ciudad">
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label *ngIf="this.userForm.controls.country.value === 'Guatemala'"
                class="uk-form-label">Municipio</label>
              <label *ngIf="this.userForm.controls.country.value !== 'Guatemala'" class="uk-form-label">Estado</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-map-pin"></i>
                </span>
                <input *ngIf="this.userForm.controls.country.value === 'Guatemala'" formControlName="state"
                  class="uk-input" type="text" placeholder="Municipio">
                <input *ngIf="this.userForm.controls.country.value !== 'Guatemala'" formControlName="state"
                  class="uk-input" type="text" placeholder="Estado">
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label class="uk-form-label">Dirección de Domicilio</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-feather-map-pin"></i>
                </span>
                <input formControlName="address" class="uk-input" type="text" placeholder="Dirección de Domicilio">
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label class="uk-form-label">Fecha de Nacimiento</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-material-outline-date-range"></i>
                </span>
                <input formControlName="birthday" class="uk-input" type="date" placeholder="Fecha de Nacimiento">
              </div>
            </div>
          </div>

          <div class="uk-width-1-2@s">
            <div class="uk-form-group">
              <label class="uk-form-label">Género</label>
              <div class="uk-position-relative w-100">
                <span class="uk-form-icon">
                  <i class="icon-line-awesome-transgender"></i>
                </span>
                <select formControlName="genre" class="uk-input uk-select">
                  <option value="" selected hidden>Género</option>
                  <option value="male">Masculino</option>
                  <option value="female">Femenino</option>
                </select>
              </div>
            </div>
          </div>

          <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
            <div class="uk-width-expand@s">
              <p>¿Ya posees una cuenta? <a style="color: #45a7f5;" [routerLink]="['/login']">Iniciar Sesión</a>
              </p>
            </div>
            <div class="uk-width-auto@s">
              <button *ngIf="!loader" (click)="registerUser()" [ngClass]="{'disabled': userForm.invalid}" type="button"
                class="button grey icon-label">
                <span class="inner-text">Crear</span>
                <span class="inner-icon"><i class="fas fa-paper-plane"></i></span>
              </button>
              <button *ngIf="loader" type="button" class="button grey icon-label">
                <span class="inner-text">Crear</span>
                <span class="inner-icon"><i class="fas fa-spinner fa-spin"></i></span>
              </button>
            </div>
          </div>

        </form>

        <!-- <div class="subnav">

          <ul class="uk-child-width-expand mb-0 uk-tab"
            uk-switcher="connect: #course-intro-tab ;animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium"
            uk-tab>
            <li class="uk-active"><a href="#" aria-expanded="true"> <i class="fas fa-user-md"></i>
                <span> Asociado </span> </a>
            </li>
            <li><a href="#" aria-expanded="false"> <i class="fas fa-user-graduate"></i>
                <span> Usuario</span></a>
            </li>
          </ul>

        </div>

        <div class="uk-grid-large mt-4 uk-grid" uk-grid="">
          <div class="uk-width-1-1@m uk-first-column">
            <ul id="course-intro-tab" class="uk-switcher" style="touch-action: pan-y pinch-zoom;">

              <li class="course-description-content uk-active">

                <form [formGroup]="partnerForm" class="uk-child-width-1-1 uk-grid-small" uk-grid>

                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label"> Nombre</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-feather-user"></i>
                        </span>
                        <input formControlName="first_name" class="uk-input" type="text" placeholder="Nombre">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label"> Apellido</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-feather-user"></i>
                        </span>
                        <input formControlName="last_name" class="uk-input" type="text" placeholder="Apellido">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Correo Electrónico</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-feather-mail"></i>
                        </span>
                        <input formControlName="email" class="uk-input" type="email" placeholder="Correo">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Teléfono</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-phone-square"></i>
                        </span>
                        <input formControlName="phone" class="uk-input" type="tel" placeholder="Teléfono">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Dirección</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-certificate"></i>
                        </span>
                        <input formControlName="address" class="uk-input" type="text"
                          placeholder="Dirección de Clínica">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Dirección de Clínica</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-certificate"></i>
                        </span>
                        <input formControlName="clinic_address" class="uk-input" type="text"
                          placeholder="Dirección de Clínica">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">País</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <select disabled class="uk-input uk-select">
                          <option value="" selected hidden>Guatemala</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Departamento</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <select formControlName="city" class="uk-input uk-select">
                          <option value="" hidden>Departamento</option>
                          <option *ngFor="let i of cities" [value]="i.descripcion_departamento">
                            {{i.descripcion_departamento}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Municipio</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-certificate"></i>
                        </span>
                        <input formControlName="state" class="uk-input" type="text" placeholder="Municipio">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Sede</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <select formControlName="campus" class="uk-input uk-select">
                          <option value="" selected hidden>Sede</option>
                          <option value="Central">Central Guatemala</option>
                          <option value="Noroccidente">Noroccidente</option>
                          <option value="Nororiente">Nororiente</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Fecha de Nacimiento</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-certificate"></i>
                        </span>
                        <input formControlName="birthday" class="uk-input" type="date"
                          placeholder="Fecha de Nacimiento">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Graduado de Especialista</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-certificate"></i>
                        </span>
                        <input formControlName="specialist_graduate" class="uk-input" type="month"
                          placeholder="Graduado de Especialista">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">No. Colegiado</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-certificate"></i>
                        </span>
                        <input formControlName="collegiate" class="uk-input" type="number" placeholder="Colegiado">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Género</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <select formControlName="genre" class="uk-input uk-select">
                          <option value="" selected hidden>Género</option>
                          <option value="male">Masculino</option>
                          <option value="female">Femenino</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s"></div>
                  <hr>
                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Formulario de Inscripción</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <div uk-form-custom="target: true">
                          <input (click)="uploadFormularioInscripcion()" style="cursor: pointer;"
                            class="uk-input uk-form-width-medium" type="button" value="PDF">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Fotografia tamaño cédula</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <div uk-form-custom="target: true">
                          <input (click)="uploadFotocopiaCedula()" style="cursor: pointer;"
                            class="uk-input uk-form-width-medium" type="button" value="JPG PNG">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Curriculum Vitae</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <div uk-form-custom="target: true">
                          <input (click)="uploadCurriculumViate()" style="cursor: pointer;"
                            class="uk-input uk-form-width-medium" type="button" value="PDF">
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="book-popular">
                    <div uk-grid>
                      <div *ngIf="formulario_inscripcion" style="margin: 0 !important" class="uk-width-1-3@s">
                        <div class="book-popular-card">
                          <img style="height: 60px !important;" width="60"
                            src="assets/images/files/{{formulario_inscripcion.type.split('/')[1]}}.png" alt=""
                            class="cover-img">
                          <div class="book-details">
                            <a target="_blank">
                              <h4>{{formulario_inscripcion.name}}</h4>
                            </a>
                            <p *ngIf="formulario_inscripcion.type === 'application/pdf'">PDF</p>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="fotografia_celuda" style="margin: 0 !important" class="uk-width-1-3@s">
                        <div class="book-popular-card">
                          <img style="height: 60px !important;" width="60"
                            src="assets/images/files/{{fotografia_celuda.type.split('/')[1]}}.png" alt=""
                            class="cover-img">
                          <div class="book-details">
                            <a target="_blank">
                              <h4>{{fotografia_celuda.name}}</h4>
                            </a>
                            <p *ngIf="fotografia_celuda.type === 'image/jpeg'">JPG</p>
                            <p *ngIf="fotografia_celuda.type === 'image/png'">PNG</p>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="curriculum_viate" style="margin: 0 !important" class="uk-width-1-3@s">
                        <div class="book-popular-card">
                          <img style="height: 60px !important;" width="60"
                            src="assets/images/files/{{curriculum_viate.type.split('/')[1]}}.png" alt=""
                            class="cover-img">
                          <div class="book-details">
                            <a target="_blank">
                              <h4>{{curriculum_viate.name}}</h4>
                            </a>
                            <p *ngIf="curriculum_viate.type === 'application/pdf'">PDF</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
                    <div class="uk-width-expand@s">
                      <p>¿Ya posees una cuenta? <a style="color: #3e416d;" [routerLink]="['/login']">Iniciar Sesión</a>
                      </p>
                    </div>
                    <div class="uk-width-auto@s">
                      <button *ngIf="!loader" (click)="registerPartner()" [ngClass]="{'disabled': partnerForm.invalid}"
                        type="button" class="button grey icon-label">
                        <span class="inner-text">Crear</span>
                        <span class="inner-icon"><i class="fas fa-paper-plane"></i></span>
                      </button>
                      <button *ngIf="loader" type="button" class="button grey icon-label">
                        <span class="inner-text">Crear</span>
                        <span class="inner-icon"><i class="fas fa-spinner fa-spin"></i></span>
                      </button>
                    </div>
                  </div>

                </form>

              </li>

              <li>

                <form [formGroup]="userForm" class="uk-child-width-1-1 uk-grid-small" uk-grid>

                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label"> Nombre</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-feather-user"></i>
                        </span>
                        <input formControlName="first_name" class="uk-input" type="text" placeholder="Nombre">
                      </div>
                    </div>
                  </div>
                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label"> Apellido</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-feather-user"></i>
                        </span>
                        <input formControlName="last_name" class="uk-input" type="text" placeholder="Apellido">
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Correo Electrónico</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-feather-mail"></i>
                        </span>
                        <input formControlName="email" class="uk-input" type="email" placeholder="Correo">
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Teléfono</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-phone-square"></i>
                        </span>
                        <input formControlName="phone" class="uk-input" type="tel" placeholder="Teléfono">
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">País</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <select formControlName="country" class="uk-input uk-select">
                          <option value="" hidden>País</option>
                          <option *ngFor="let i of countries" [value]="i.nombre">
                            {{i.nombre}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label *ngIf="this.userForm.controls.country.value === 'Guatemala'"
                        class="uk-form-label">Departamento</label>
                      <label *ngIf="this.userForm.controls.country.value !== 'Guatemala'"
                        class="uk-form-label">Ciudad</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <select *ngIf="this.userForm.controls.country.value === 'Guatemala'" formControlName="city"
                          class="uk-input uk-select">
                          <option value="" hidden>Departamento</option>
                          <option *ngFor="let i of cities" [value]="i.descripcion_departamento">
                            {{i.descripcion_departamento}}
                          </option>
                        </select>
                        <input *ngIf="this.userForm.controls.country.value !== 'Guatemala'" formControlName="city"
                          class="uk-input" type="text" placeholder="Ciudad">
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-3@s">
                    <div class="uk-form-group">
                      <label *ngIf="this.userForm.controls.country.value === 'Guatemala'"
                        class="uk-form-label">Municipio</label>
                      <label *ngIf="this.userForm.controls.country.value !== 'Guatemala'"
                        class="uk-form-label">Estado</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <input *ngIf="this.userForm.controls.country.value === 'Guatemala'" formControlName="state"
                          class="uk-input" type="text" placeholder="Municipio">
                        <input *ngIf="this.userForm.controls.country.value !== 'Guatemala'" formControlName="state"
                          class="uk-input" type="text" placeholder="Estado">
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-2@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Dirección</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-certificate"></i>
                        </span>
                        <input formControlName="address" class="uk-input" type="text" placeholder="Dirección">
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-4@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Fecha de Nacimiento</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-certificate"></i>
                        </span>
                        <input formControlName="birthday" class="uk-input" type="date"
                          placeholder="Fecha de Nacimiento">
                      </div>
                    </div>
                  </div>

                  <div class="uk-width-1-4@s">
                    <div class="uk-form-group">
                      <label class="uk-form-label">Género</label>
                      <div class="uk-position-relative w-100">
                        <span class="uk-form-icon">
                          <i class="icon-line-awesome-transgender"></i>
                        </span>
                        <select formControlName="genre" class="uk-input uk-select">
                          <option value="" selected hidden>Género</option>
                          <option value="male">Masculino</option>
                          <option value="female">Femenino</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
                    <div class="uk-width-expand@s">
                      <p>¿Ya posees una cuenta? <a style="color: #3e416d;" [routerLink]="['/login']">Iniciar Sesión</a>
                      </p>
                    </div>
                    <div class="uk-width-auto@s">
                      <button *ngIf="!loader" (click)="registerUser()" [ngClass]="{'disabled': userForm.invalid}"
                        type="button" class="button grey icon-label">
                        <span class="inner-text">Crear</span>
                        <span class="inner-icon"><i class="fas fa-paper-plane"></i></span>
                      </button>
                      <button *ngIf="loader" type="button" class="button grey icon-label">
                        <span class="inner-text">Crear</span>
                        <span class="inner-icon"><i class="fas fa-spinner fa-spin"></i></span>
                      </button>
                    </div>
                  </div>

                </form>

              </li>

            </ul>
          </div>

        </div> -->

      </div>

    </div>
  </div>
</div>