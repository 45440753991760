<div uk-height-viewport class="uk-flex uk-flex-middle">
  <div class="uk-width-2-3@m uk-width-1-2@s m-auto rounded">
    <div class="uk-child-width-1-2@m uk-grid-collapse bg-gradient-grey" uk-grid>

      <div class="uk-margin-auto-vertical uk-text-center uk-animation-scale-up p-3 uk-light">
        <!-- <i class=" uil-graduation-hat icon-large"></i> -->
        <img width="150" src="assets/images/logo-light.png" alt="">
        <br>
        <h1>AGOG Virtual</h1>
        <p>Asociación de Ginecología y Obstetricia de Guatemala</p>
      </div>

      <div class="uk-card-default p-6">
        <div class="my-4 uk-text-center">
          <h2 class="mb-0">Bienvenido</h2>
          <p class="my-2">Accede con tus credenciales</p>
        </div>
        <form [formGroup]="loginForm">

          <div class="uk-form-group">
            <label class="uk-form-label">Correo Electrónico</label>
            <div class="uk-position-relative w-100">
              <span class="uk-form-icon">
                <i class="icon-feather-mail"></i>
              </span>
              <input formControlName="email" class="uk-input" type="email" placeholder="Correo Electrónico">
            </div>
          </div>

          <div class="uk-form-group">
            <label class="uk-form-label">Contraseña
              <a style="float: right; color: #45a7f5" class="uk-text-small"
                [routerLink]="['/lost-password']">Restablecer Contraseña</a>
            </label>

            <div class="uk-position-relative w-100">
              <span class="uk-form-icon">
                <i class="icon-feather-lock"></i>
              </span>
              <input id="password" formControlName="password" class="uk-input" type="password" placeholder="Contraseña">
            </div>

            <label style="margin-top: 5px; margin-bottom: 38px;" class="uk-form-label">
              <a (click)="viewPassword()" style="float: right; color: #45a7f5" class="uk-text-small">
                <span id="text">Ver Contraseña</span>
              </a>
            </label>
          </div>

          <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
            <div class="uk-width-expand@s">
              <p>¿No tienes una cuenta? <a style="color: #45a7f5;" [routerLink]="['/register']">Regístrate</a></p>
            </div>
            <div class="uk-width-auto@s">
              <button *ngIf="!loader" (click)="login()" [ngClass]="{'disabled': loginForm.invalid}" type="submit"
                class="button grey icon-label">
                <span class="inner-text">Acceder</span>
                <span class="inner-icon"><i class="fas fa-sign-in-alt"></i></span>
              </button>
              <button *ngIf="loader" type="button" class="button grey icon-label">
                <span class="inner-text">Acceder</span>
                <span class="inner-icon"><i class="fas fa-spinner fa-spin"></i></span>
              </button>
            </div>
          </div>

        </form>
      </div>

    </div>
  </div>
</div>