<router-outlet></router-outlet>

<div [ngClass]="{'footer-app': !this.mobile}" class="page-content-inner">
  <div class="footer">
    <div class="uk-grid-collapse" uk-grid>
      <div class="uk-width-expand@s uk-first-column">
        <p>© 2023 <strong>AGOG</strong>. Todos los derechos reservados. </p>
      </div>
      <div class="uk-width-auto@s">
        <nav class="footer-nav-icon">
          <ul>
            <li><a target="_blank" href="https://www.facebook.com/AGOG.GT"><i class="icon-brand-facebook"></i></a></li>
            <li><a target="_blank" href="https://www.instagram.com/agog.gt"><i class="icon-brand-instagram"></i></a></li>
            <li><a target="_blank" href="https://api.whatsapp.com/send?phone=50230627226"><i class="icon-brand-whatsapp"></i></a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</div>