import { Injectable } from '@angular/core';
import { RootService } from './root.service';
import { Router } from '@angular/router';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private rootService: RootService, private router: Router) { }

  dataUser: any;

  login(data: any): Promise<any> {
    return this.rootService.authPost('/login', data);
  }

  register(data: any): Promise<any> {
    return this.rootService.authPost('/reg1', data);
  }

  postDocument(id: number, doc: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('doc', doc.toString());
    formData.append('file', file);
    return this.rootService.file('/document', formData);
  }

  postLessonFile(id: number, module_slug: string, lesson_slug: string, file_name: string, file: any): Promise<any> {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('module_slug', module_slug);
    formData.append('lesson_slug', lesson_slug);
    formData.append('file_name', file_name);  
    formData.append('file', file);
    return this.rootService.file('/lesson/file', formData);
  }

  lostPassword(data: any): Promise<any> {
    return this.rootService.authPost('/lost-password', data);
  }

  validateCode(data: any, id: number): Promise<any> {
    return this.rootService.authPut('/code/' + id, data);
  }

  restore(data: any, id: number): Promise<any> {
    return this.rootService.authPut('/restore/' + id, data);
  }

  verify(data: any): Promise<any> {
    return this.rootService.authPost('/verify', data);
  }

  verifyAccess(): boolean {
    const tokenString = localStorage.getItem('token');
    if (tokenString) {
      try {
        this.dataUser = decode(tokenString);
        const data = {
          id: this.dataUser.id,
          token: tokenString
        };
        this.verify(data).then(res => {
          if (res.success) {
            if (this.dataUser.role !== this.router.url.split('/')[1]) {
              this.router.navigate([this.dataUser.role]);
            }
          } else {
            return false;
          }
        });
        return true;
      } catch (error) {
        return false;
      }
    } else {
      return false;
    }
  }

  isAuthenticated(): boolean {
    try {
      const tokenString = localStorage.getItem('token');
      if (!tokenString) {
        return false;
      }
      const token: any = decode(tokenString);
      return token.exp ? true : false;
    } catch (error) {
      return false;
    }
  }
}
