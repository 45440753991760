import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { default as decode } from 'jwt-decode';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.css']
})
export class RestoreComponent implements OnInit {

  constructor(private authService: AuthService, private alert: AlertService, private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(res => {
      this.id = res.code.split('-')[1];
      const data = {
        code: res.code.split('-')[0]
      };
      this.authService.validateCode(data, this.id).then(res_ => {
        if (res_.success) {
          this.restoreForm.controls.code.setValue(res.code.split('-')[0]);
        } else {
          this.router.navigate(['/login']);
        }
      });
    });
    this.restoreForm = new FormGroup({
      'password': new FormControl('', [Validators.required]),
      'password2': new FormControl('', [Validators.required]),
      'code': new FormControl(null, [Validators.required])
    });
  }

  restoreForm: FormGroup;
  id: number;

  loader: boolean;
  dataUser: any;

  ngOnInit() {
  }

  restore() {
    if (!this.restoreForm.invalid) {
      this.loader = true;
      this.authService.restore(this.restoreForm.value, this.id).then(res => {
        if (res.success) {
          this.alert.alertMax('Correcto', res.message, 'success');
          this.router.navigate(['/login']);
        } else {
          this.alert.alertMax('Error', res.message, 'error');
        }
        this.loader = false;
      });
    } else {
      this.alert.alertMax('Error', 'Completa los campos', 'warning');
    }
  }

  viewPassword() {
    var tipo1: any = document.getElementById("password1");
    var tipo2: any = document.getElementById("password2");
    var text: any = document.getElementById("text");
    if (tipo1.type == "password") {
      tipo1.type = "text";
      tipo2.type = "text";
      text.textContent = "Ocultar Contraseñas";
    } else {
      tipo1.type = "password";
      tipo2.type = "password";
      text.textContent = "Ver Contraseñas";
    }
  }

}
